import { ADD_ON_PRICE, CLEANING_TYPE_PRICE } from "./pricing";

export const bedroomOptions = [
  {
    label: "Studio",
    value: "0",
  },
  ...Array(5)
    .fill(null)
    .map((_, i) => ({
      label: `${i + 1}`,
      value: `${i + 1}`,
    })),
];

export const bathroomOptions = [...bedroomOptions.slice(1)];

export const cleaningTypes = [
  {
    label: "Standard",
    value: CLEANING_TYPE_PRICE.Standard,
    subText: "2.5-3 hours",
  },
  {
    label: "Special Mission",
    value: CLEANING_TYPE_PRICE["Special Mission"],
    subText: "3.5-4 hours",
  },
  {
    label: "Super Clean",
    value: CLEANING_TYPE_PRICE["Super Clean"],
    subText: "6.5-7 hours",
  },
  {
    label: "Move In / Out",
    value: CLEANING_TYPE_PRICE["Move In / Out"],
    subText: "7.5-8 hours",
  },
];

export const frequencyOptions = [
  {
    label: "One Time",
    value: 0,
    tag: null,
  },
  {
    label: "Weekly",
    value: 20,
    tag: "Save 20%",
  },
  {
    label: "Every 2 weeks",
    value: 15,
    tag: "Save 15%",
  },
  {
    label: "Every 4 weeks",
    value: 10,
    tag: "Save 10%",
  },
];

export const addOnOptions = [
  {
    label: "Organizing",
    value: "Organizing",
    image: "organizing.svg",
    altText: "Organizing Icon",
    cost: ADD_ON_PRICE.organizing,
  },
  {
    label: "Inside Oven",
    value: "Inside Oven",
    image: "oven.svg",
    altText: "Oven Icon",
    cost: ADD_ON_PRICE.oven,
  },
  {
    label: "Inside Fridge",
    value: "Inside Refrigerator",
    image: "fridge.svg",
    altText: "Fridge Icon",
    cost: ADD_ON_PRICE.fridge,
  },
  {
    label: "Inside Cabinets",
    value: "Inside Cabinets",
    image: "inside_cabinets.svg",
    altText: "Cabinet Icon",
    cost: ADD_ON_PRICE.cabinets,
  },
  {
    label: "Interior Windows",
    value: "Interior Windows",
    image: "windows.svg",
    altText: "Windows Icon",
    cost: ADD_ON_PRICE.windows,
  },
  {
    label: "Laundry Wash & Dry",
    value: "Laundry wash & dry",
    image: "laundry.svg",
    altText: "Laundry Icon",
    cost: ADD_ON_PRICE.laundry,
  },
];

export const addOnDbValue = {
  Organizing: "organizing",
  "Inside Oven": "oven",
  "Inside Refrigerator": "fridge",
  "Inside Cabinets": "cabinets",
  "Interior Windows": "windows",
  "Laundry wash & dry": "laundry",
};

export const getInTypeOptions = [
  {
    label: "Someone at home",
    value: "Someone in home",
  },
  {
    label: "Doorman",
    value: "Doorman",
  },
  {
    label: "Hidden Key",
    value: "Hidden Key",
  },
  {
    label: "Other",
    value: "Other",
  },
];

export const petOptions = [
  {
    label: "No Pets",
    value: "No Pets",
  },
  {
    label: "Dog",
    value: "Dog",
  },
  {
    label: "Cat",
    value: "Cat",
  },
  {
    label: "Others",
    value: "Others",
  },
];
